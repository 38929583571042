<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps<{ modelValue: string }>()

const emit = defineEmits<{
  'update:modelValue': [payload: string]
}>()

const otps = ref(['', '', '', ''])
const error = ref('')

const inputs = ref([])

const handleChange = (event:any, index:any) => {
  // Remove non-numeric characters
  otps.value[index] = inputs.value[index]?.value.replace(/\D/g, '')

  // Validate length
  if (inputs.value[index].value.length > 1) {
    error.value = 'Each OTP digit must be 1 character long.'
  } else {
    error.value = ''
  }
  // Focus next input or emit OTP when all digits entered
  if (inputs.value[index].value.length === 1 && index < inputs.value.length - 1) {
    inputs.value[index + 1].focus()
  } else if (otps.value.every((otp) => otp.length === 1)) {
    emit('update:modelValue', otps.value.join(''))
  }
}

const handleKeyDown = (event:any, index:any) => {
  // Move focus to the previous input when backspace is pressed on an empty input
  if (event.key === 'Backspace' && index > 0 && inputs.value[index].value === '') {
    otps.value[index] = inputs.value[index].value
    inputs.value[index - 1].focus()
  }
}

watch(
  () => props.modelValue,
  (v) => {
    if (v?.length) {
      otps.value = v.split('')
    } else {
      otps.value = ['', '', '', '']
    }
  },
  {
    immediate: true,
  }
)
</script>

<template>
  <div class="d-flex align-items-center justify-content-center gap-2 mb-2" >
    <div  class="otp-input" v-for="(otp, index) in otps" :key="index">
      <input
        ref="inputs"
        :value="otp"
        :model-value="otp"
        type="text"
        @input="(event) => handleChange(event, index)"
        maxlength="1"
        dir="ltr"
        pattern="[0-9]*"
        @keydown="(event) => handleKeyDown(event, index)"
      />
    </div>
  </div>
  <div v-if="error" class="error">{{ error }}</div>
</template>

<style scoped>
.otp-input input {
  width: 56px;
  height: 56px;
  border: 1px solid #7a7a7a;
  border-radius: 8px;
  outline: none;
  background: transparent;
  font-size: 1.2rem;
  padding: 1rem;
  text-align: center;
  border: 2px solid #2d3291;
}
.otp-input:focus {
  border: 1px solid #1ca3d5;
}
.error {
  color: red;
  font-size: 0.8rem;
}
</style>
