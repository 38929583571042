<script lang="ts" setup>
const { $api } = useNuxtApp();

const props = defineProps<{
  phone: string;
  visible: boolean;
}>();

const emit = defineEmits<{
  "update:visible": [payload: boolean];
  "update:phone": [payload: string];
  success: [];
}>();

const isSubmitting = ref(false);
const message = ref("");
const errors = ref([]);

const onClose = () => {
  message.value = "";
  errors.value = [];
  emit("update:visible", false);
};

const onSubmit = async () => {
  try {
    message.value = "";
    errors.value = [];
    isSubmitting.value = true;

    let phone = props.phone; // Get the phone number from props
    if (phone && phone.charAt(0) !== "0" && phone.charAt(0) === "5") {
      phone = "0" + phone; // Add '0' at the beginning if it starts with '5'
    }
    const res = await $api("/request-otp", {
      method: "POST",
      body: {
        phone: phone,
      },
    });
    if (res.sent) {
      message.value = res.message;
      onClose();
      emit("success");
    }
  } catch (e) {
    errors.value = e.data.validation_errors;
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<template>
  <BModal
    :model-value="visible"
    @update:model-value="(v) => emit('update:visible', v)"
    size="lg"
    hide-header
    hide-footer
    body-class="p-0"
    content-class="rounded-5 overflow-hidden"
  >
    <template #default>
      <div class="row g-0">
        <div class="col-lg-7">
          <div class="row justify-content-center">
            <div class="col-lg-11">
              <div
                class="h-500 d-flex align-items-center flex-column justify-content-center"
              >
                <div>
                  <div class="fs-32 fw-700 text-center mb-3" >
                    {{ $t("login_modal_title") }}
                  </div>
                  <div class="fs-16 fw-400 textPrimary text-center mb-4">
                    {{ $t("login_modal_subtitle") }}
                  </div>

                  <AppPhoneInput
                    :model-value="phone"
                    @update:model-value="(v:any) => emit('update:phone', v)"
                  />

                  <BButton
                    variant="primary"
                    class="mt-4 w-100"
                    block
                    size="lg"
                    @click="onSubmit"
                    :loading="isSubmitting"
                    loading-text=""
                  >
                    {{ $t('login') }}
                  </BButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-none d-lg-block col-lg-5">
          <img src="assets/images/modal-car.png" class="w-100" alt="" />
        </div>
      </div>
    </template>
  </BModal>
</template>

<style lang="css" scoped>
.custom-modal-body {
  border-radius: 25px;
}

@media (max-width: 767px) {
  .modal-car {
    display: none;
  }
}
</style>
